<template>
  <el-form
    :model="addForm"
    :rules="rules"
    ref="addForm"
    label-width="82px"
    label-position="left"
    class="demo-form dialog-form"
  >
    <el-form-item label="行业名称：" prop="name">
      <el-input v-model.trim="addForm.name" placeholder="请输入行业名称"></el-input>
    </el-form-item>
    <el-form-item label="行业编码：" prop="code">
      <el-input v-model.trim="addForm.code" placeholder="请输入行业编码"></el-input>
    </el-form-item>
    <el-form-item label="行业描述：">
      <el-input
        type="textarea"
        :autosize="{ minRows: 2 }"
        maxlength="50"
        show-word-limit
        placeholder="请输入内容"
        v-model.trim="addForm.description"
      ></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
import { checkRoleName, checkRoleCode } from '@/common/utils/index'
import {
  insertBusiness,
  updateBusiness,
  getBusinessById
} from "@/api/lib/api.js";

export default {
  components: {
  },
  props: {
    businessId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      menuId: [],
      addForm: {
        code: "code12345",
        description: "测试行业",
        id: 0,
        name: "测试行业12345",
      },
      rules: {
        name: [
          { required: true, message: "请输入行业名称", trigger: "blur" },
          { validator: checkRoleName, trigger: "blur" }

        ],
        code: [
          { required: true, message: "请输入行业编码", trigger: "change" },
          { validator: checkRoleCode, trigger: "blur" }

        ],
      },
      companyList: []
    };
  },
  methods: {
    // 新增/保存用户
    onSave () {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          let data = {
            code: this.addForm.code,
            description: this.addForm.description,
            name: this.addForm.name,
          };
          if (this.businessId) {
            data.id = this.addForm.id;
            updateBusiness(data).then(res => {
              if (res.code === 0) {
                this.$message.success("修改成功");
              } else {
                this.$message.error(res.message);
              }
              this.$emit("onDialogClose", res.code === 0);
            });
          } else {
            insertBusiness(data).then(res => {
              if (res.code === 0) {
                this.$message.success("新增成功");
              } else {
                this.$message.error(res.message);
              }
              this.$emit("onDialogClose", res.code === 0);
            });
          }
        }
      });
    }
  },
  created () {
    if (this.businessId) {
      getBusinessById(this.businessId).then(res => {
        if (res.code === 0) {
          this.addForm = { ...res.data };
        }
      });
    }
  },
  mounted () { }
};
</script>